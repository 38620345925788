.table-container {
    display: flex;
    justify-content: center;
    /* padding: 20px; */
}

.nutrition-assessment-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.nutrition-assessment-table th,
.nutrition-assessment-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.nutrition-assessment-table thead th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.label-cell {
    background-color: #bfc3ff; /* Yellow background color */
    font-weight: bold;
    text-align: left;
}

.nutrition-assessment-table td[colspan="6"] {
    background-color: #f9f9f9;
}

.nutrition-assessment-table th[rowspan="2"] {
    width: 200px;
}